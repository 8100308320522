require('slick-carousel')

export default class LibSlicksReviewSlider {
  constructor() {
    setTimeout(this.slickInit, 0)
  }

  slickInit() {
    const reviewSlider = $('[data-ref="reviewSlider"]')
    if (!reviewSlider || reviewSlider.length === 0) return false
    if (reviewSlider.get(0).classList.contains('slick-initialized')) return false

    reviewSlider.slick({
      dots: true,
      adaptiveHeight: false,
      arrows: true,
      slidesToShow: 1,
      prevArrow: '<button type="button" class="slick-prev"><i class="fa fa-chevron-left fa-thin" aria-hidden="true" /></button>',
      nextArrow: '<button type="button" class="slick-next"><i class="fa fa-chevron-right fa-thin" aria-hidden="true" /></button>',
      mobileFirst: true,
      responsive: [{
        breakpoint: 1000,
        settings: {
          slidesToShow: 2
        }
      }]
    })
  }
}
